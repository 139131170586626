import { storyData } from './climate-change';
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/tmp/building-future/innovation_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/tmp/building-future/innovation_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/tmp/building-future/innovation_card_img3@2x.jpg';
import InovationImg from '@/assets/img/tmp/building-future/innovation_img@2x.png';
import InovationImgM from '@/assets/img/tmp/building-future/innovation_imgM@2x.png';
import WedoIco1 from '@/assets/img/tmp/building-future/innovation_obj1@2x.png';
import WedoIco2 from '@/assets/img/tmp/building-future/innovation_obj2@2x.png';
import WedoIco3 from '@/assets/img/tmp/building-future/innovation_obj3@2x.png';
import WedoIco4 from '@/assets/img/tmp/building-future/innovation_obj4@2x.png';
import WedoIco5 from '@/assets/img/tmp/building-future/innovation_obj5M@3x.png';
import WedoIco6 from '@/assets/img/tmp/building-future/innovation_obj6@2x.png';
import WedoIco7 from '@/assets/img/tmp/building-future/innovation_obj7@2x.png';
import WedoIco8 from '@/assets/img/tmp/building-future/innovation_obj8@2x.png';
import WedoIco9 from '@/assets/img/tmp/building-future/innovation_obj9@2x.png';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTop,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `생명을 구하는 정보와 서비스 제공`,
    dec: `부족한 자원을 효율적으로사용하고 누구나 삶에 필요한 기본적인 서비스를 누리며 생명을 구하는 정보에 접근할 수 있도록 기술을 사용합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `전 세계 청년 참여 독려`,
    dec: `전 세계 젊은이의 참여와 그들을 각국 정부와 연결해 미래를 보장할 수있는 기회를 마련합니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `어린이를 최우선으로`,
    dec: `유니세프는 새로운 기술을 정의하고, 연구를 수행하고, 새로운 접근법을 실험하고, 초기 단계 기술에 투자합니다. 무엇보다 어린이가 기술의 이점을 누리고, 기술의 위험으로부터 보호받을 수 있도록 합니다.`,
    img: CardImg3,
  },
];

const wedo = [
  {
    id: 1,
    img: WedoIco1,
    desc: [
      {
        dec: `1. 사용자와 함께 <br />설계할 것`,
      },
    ],
  },
  {
    id: 2,
    img: WedoIco2,
    desc: [
      {
        dec: `2. 현 생태계를 <br />이해할 것`,
      },
    ],
  },
  {
    id: 3,
    img: WedoIco3,
    desc: [
      {
        dec: `3. 실제 사업으로 <br />이어지도록 설계할 것`,
      },
    ],
  },
  {
    id: 4,
    img: WedoIco4,
    desc: [
      {
        dec: `4. 지속가능성을 <br />고려할 것`,
      },
    ],
  },
  {
    id: 5,
    img: WedoIco5,
    desc: [
      {
        dec: `5. 데이터를 <br />기반으로 할 것`,
      },
    ],
  },
  {
    id: 6,
    img: WedoIco6,
    desc: [
      {
        dec: `6. 누구나 접근 가능한 <br />데이터를 사용할 것`,
      },
    ],
  },
  {
    id: 7,
    img: WedoIco7,
    desc: [
      {
        dec: `7. 재사용할 수 있고 <br />기능을 향상 시킬 것`,
      },
    ],
  },
  {
    id: 8,
    img: WedoIco8,
    desc: [
      {
        dec: `8. 사생활과 보안에 <br />신경 쓸 것`,
      },
    ],
  },
  {
    id: 9,
    img: WedoIco9,
    desc: [
      {
        dec: `9. 협력하여 <br />이룰 것`,
      },
    ],
  },
];
const SectionRule = styled(Section)`
  h2 {
    margin-bottom: 64px;
  }

  .swiper-slide {
    width: 160px;
    margin-right: 80px;
  }

  ${breakpoint(`mobile`)} {
    h2 {
      margin-bottom: 48px;
    }

    .swiper-slide {
      width: 120px;
      margin-right: 24px;
    }
  }
`;
const ProcessDL = styled.dl`
  dt {
    text-align: center;
    .img-wrap {
      max-width: 160px;
      width: 100%;
      display: inline-block;

      .img-box {
        width: 100%;
        padding-top: 100%;
      }

      ${breakpoint(`mobile`)} {
        max-width: 80px;
      }
    }
  }

  dd {
    margin-top: 24px;
    ul {
      & > li {
        text-align: center;
        display: block;
        width: 100%;
        position: relative;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }

        & > * {
          display: inline-block;
        }

        span + span {
          display: block;
        }
      }
    }
  }
`;

const SectionInnovation = styled(Section)`
  h2 {
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 316px;
      height: 316px;
      background: #1cabe2;
      border-radius: 100%;
      position: absolute;
      left: -116px;
      top: -134px;
      z-index: -1;

      ${breakpoint(1160)} {
        width: 27.24vw;
        height: 27.24vw;
        left: -10vw;
        top: -11.55vw;
      }

      ${breakpoint(`mobile`)} {
        width: 174px;
        height: 174px;
        left: -64px;
        top: -73px;
      }
    }
  }

  .article {
    margin-bottom: 96px;
    position: relative;
    &:last-child {
      margin-bottom: 0 !important;
    }

    dd {
      margin-top: 32px;
      line-height: 2;
    }

    &.flex {
      padding-right: 45%;

      dl {
        padding-right: 50px;
      }
      .col-img {
        width: 45%;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    ${breakpoint(`tablet`)} {
      margin-bottom: 72px;

      &.flex {
        padding-right: 0;
        flex-wrap: wrap;

        dl {
          padding-right: 0;
          margin-bottom: 48px;
        }
        .col-img {
          width: 100%;
          position: relative;
        }
      }
    }
  }
`;

const Innovation: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST07'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A011'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="이노베이션"
      description="for every child, a new idea"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 보다 나은 <br />
                  삶을 살 권리가 있습니다
                </PageComponent>
              </Tit>
            </h2>
            <p className="head-dec">
              <PageComponent id="content1">
                유니세프는 70년이 넘는 시간동안 어린이를 위한 혁신의 역사를 이어
                왔습니다. 새로운 접근법과 파트너십을 활용한 기술 혁신은
                아동권리를 실현하고 삶의 질을 높이는데 중요한 역할을 합니다.
                유니세프는 혁신적으로 개발도상국의 사회 문제를 해결하고
                지속가능한 발전을 구현하는 것이 주요 목표입니다. 유니세프
                이노베이션 랩은 각국 정부, 기업, 대학, 시민사회와 파트너십을
                구축해 어린이와 청년을 위한 지속가능발전 방법을 연구하고,
                지역사회의 어려움을 해결할 수 있는 체계적이고 효율적인 솔루션을
                개발하여 현장에 적용합니다.
              </PageComponent>
            </p>
          </SectionHeader>
        </Container>
        <PageComponent id="youtube1">
          <YoutubeSingle videoId="68d1ux8HhhU" />
        </PageComponent>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionRule className="by-sub-main-layout">
        <Container>
          <h2>
            <Tit size="s3-1">디지털 개발 원칙 준수</Tit>
          </h2>

          <SwiperBasic
            overflowHidden
            css={`
              padding: 0;
            `}
          >
            <Swiper
              slidesPerView="auto"
              navigation
              scrollbar={{ draggable: true }}
            >
              {wedo.map((row) => (
                <SwiperSlide key={row.id}>
                  <ProcessDL>
                    <dt>
                      <div className="img-wrap">
                        <div
                          className="img-box"
                          css={`
                            background-image: url(${row.img});
                          `}
                        />
                      </div>
                    </dt>
                    <dd>
                      <ul className="dec-ul">
                        {row.desc.map((row2) => (
                          <li>
                            <p dangerouslySetInnerHTML={{ __html: row2.dec }} />
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </ProcessDL>
                </SwiperSlide>
              ))}
            </Swiper>
          </SwiperBasic>
        </Container>
      </SectionRule>

      <SectionInnovation className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                전 세계 파트너와 함께 <br />
                유니세프가 이룬 혁신
              </Tit>
            </h2>
          </SectionHeader>

          <div className="article-container">
            <article className="article flex">
              <dl>
                <dt>
                  <Tit size="s2" color="sky">
                    아프리카 지하수 시추프로젝트
                  </Tit>
                </dt>
                <dd>
                  인공위성을 활용해 시추 정확도를 최대 90-95% 높여 아프리카 어린이에게 깨끗한 물을 선물했습니다.
                </dd>
              </dl>

              <div className="col-img">
                <Image pcSrc={InovationImg} mobileSrc={InovationImgM} />
              </div>
            </article>

            <article className="article">
              <dl>
                <dt>
                  <Tit size="s2" color="sky">
                    몽골 위생 시설 및 폐수 처리 시설
                  </Tit>
                </dt>
                <dd>
                  추운 기후에도 잘 작동할 수 있는 혁신적인 폐수 처리 및 위생시설을 오지 지역사회나 학교 등에 설치해 어린이의 건강을 지킵니다.
                </dd>
              </dl>
            </article>

            <article className="article">
              <dl>
                <dt>
                  <Tit size="s2" color="sky">
                    가자지구 어린이를 지키는 혁신
                  </Tit>
                </dt>
                <dd>
                  프론티어 데이터 기술은 공간 기술, 위성 이미지, AI 및 최첨단 데이터 기술을 결합한 기술로 학교 외에도 식수 및 위생시설, 의료시설의 피해 상태를 실시간으로 확인할 수 있습니다. 현장 접근이 어려운 가자지구 내 학교 563곳의 상황을 원격으로 즉시 모니터링 할 수 있습니다. 이를 통해 유니세프는 가자지구 어린이를 조금 더 효율적으로 정확하게 보호할 수 있습니다.
                </dd>
              </dl>
            </article>
          </div>
        </Container>
      </SectionInnovation>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A011" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Innovation;
